import { createIntl, createIntlCache } from 'react-intl'
import { AnyObject, FormattedMessageId } from 'types'
import en from './en.json'
import pt from './pt.json'

type Messages = {
  [locale: string]: {
    [key: string]: string
  }
}

export enum Locale {
  English = 'en',
  Portuguese = 'pt',
}

const language = window.navigator.languages[0].split('-')[0]
const isPortuguese: boolean = language === Locale.Portuguese

export const locale = isPortuguese ? Locale.Portuguese : Locale.English
export const messagesByLocale: Messages = { en, pt: { ...en, ...pt } }

const cache = createIntlCache()

export const intl = createIntl(
  {
    locale,
    messages: messagesByLocale[locale],
  },
  cache
)

export const intlMessageForId: (
  id: FormattedMessageId,
  values?: AnyObject
) => string = (id, values = {}) => {
  return intl.formatMessage(
    {
      id,
    },
    values
  )
}

/**
 * convenience function to handle multiple translations with the same base lookup key
 * @param base string that's reused for multiple intl translations
 * @returns function that takes an intl key and returns a translated string
 */
export const getIntlMessageForId: (
  base: string
) => (appended: string) => string = (base) => (appended) =>
  intlMessageForId(`${base}.${appended}`)
